import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-app',
  templateUrl: './faq-app.component.html',
  styleUrls: ['./faq-app.component.scss']
})
export class FaqAppComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
