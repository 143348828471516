import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-website',
  templateUrl: './faq-website.component.html',
  styleUrls: ['./faq-website.component.scss']
})
export class FaqWebsiteComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
